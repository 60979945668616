import React, { useEffect, useState } from 'react'
import { Form, Container, Row } from 'react-bootstrap'
import { withTrans } from '../../i18n/withTrans'
import { useTranslation } from 'react-i18next'
import MetaTags from '../MetaTags'
import Navigation from '../../components/Navigation'
import Footer from '../../components/Footer'
import axios from 'axios'
import swal from 'sweetalert'

import './start.scss'
import banner_desktop from '../../images/socmed/banner-socmed-desktop.webp'

const HairQuizStart = ({ pageContext }) => {
    const { t } = useTranslation()
    const [currentStep, setCurrentStep] = useState(0)
    const [quizData, setQuizData] = useState([])
    const [selectedAnswers, setSelectedAnswers] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (pageContext.quizData) {
            setQuizData(pageContext.quizData)
            setCurrentStep(1)
        }
    }, [pageContext.quizData])

    const handleResponse = async (questionId, answerId) => {
        const updatedAnswers = { ...selectedAnswers, [questionId]: answerId }
        setSelectedAnswers(updatedAnswers)

        if (currentStep === quizData.length) {
            // If it's the last question, submit the quiz
            await handleSubmit(updatedAnswers)
        } else {
            // Otherwise, go to the next step
            setCurrentStep(currentStep + 1)
        }
    }

    const handleSubmit = async (answers) => {
        setIsSubmitting(true)
        const lang = localStorage.getItem('lang') || 'en'
        const queryParams = Object.keys(answers).map((key) => `answer${key}=${answers[key]}`).join('&')
        try {
            const apiUrl = `${process.env.GATSBY_API_URL}/api/v1/results?${queryParams}`
            const response = await axios.get(apiUrl)
            window.location.href = `/${lang}/hair-quiz-result?${queryParams}`
            console.log('API Response:', response.data)
        } catch (error) {
            swal({
                title: 'Result Not Match!',
                text: error + ' Please try again later.',
                icon: 'error',
                button: 'OK',
            }).then(function () {
                window.location.href = `/${lang}/hair-quiz-start`
            })
        }
        setIsSubmitting(false)
    }

    const renderFormQuiz = () => {
        const question = quizData[currentStep - 1]
        if (!question) return null

        return (
            <Form>
                <div className="box-question">{question.question.en}</div>
                <Row>
                    {question.answer_list.map((option, index) => (
                        <div className="col-6 col-md-4 col-xl-3" key={index}>
                            <div className="box-answer">
                                <div className="box-icon"></div>
                                <div className="box-text">
                                    <Form.Check
                                        type="radio"
                                        id={`option-${index}`}
                                        label={option.answer.en}
                                        name={`answer${currentStep}`}
                                        disabled={isSubmitting}
                                        checked={selectedAnswers[question._id] === option._id}
                                        onChange={() => {
                                            console.log(`Selected option${currentStep}:`, option._id)
                                            handleResponse(currentStep, option._id)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </Row>
            </Form>
        )
    }

    return (
        <div className="quiz-page">
            <MetaTags
                title="Ellips | Hair Quiz"
                description={t('quiz.meta-desc')}
                image={banner_desktop}
                url={typeof window !== 'undefined' ? window.location.href : ''}
            />
            <Navigation activeLanguage={pageContext.lang} />
            <div className="quiz-page-container">
                <div className="box-heading">
                    <Container>
                        <div className="box-quiz text-center mont-regular">
                            <div className="title-quiz">Ellips Hair Quiz</div>
                            <div className="step-question">
                                Question {currentStep}/{quizData.length}
                            </div>
                            {renderFormQuiz()}
                        </div>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withTrans(HairQuizStart)
